import { DialogContentText } from "@mui/material";
import { translationKeys } from "../../translations/main-translations";
import { Partialize } from "../../types/helpers";
import UploadInput from "../UploadInput";
import ActionItemDialog, { IActionItemDialogProps } from "./ActionItemDialog";

interface IUploadFileDialogProps extends Partialize<Omit<IActionItemDialogProps, "onPrimary">, "primary"> {
    onUpload: IActionItemDialogProps["onPrimary"];
    file: File | null;
    updateFile: (newFile: File, isValid: boolean) => void;
    mimeTypes?: string | string[];
    customHelperText?: string | null;
}

const UploadFileDialog = ({ onUpload, file, updateFile, translate, customHelperText, mimeTypes, ...other }: IUploadFileDialogProps) => (
    <ActionItemDialog
        action="upload"
        item="file"
        primary={translationKeys.VDLANG_UPLOAD}
        onPrimary={onUpload}
        translate={translate}
        title={translate(translationKeys.VDLANG_UPLOAD_SELECT_DOCUMENT_PHOTO)}
        {...other}
    >
        <DialogContentText>{translate(translationKeys.VDLANG_FILE_SIZE_UPLOAD)}</DialogContentText>
        <UploadInput translate={translate} updateFile={updateFile} file={file} mimeTypes={mimeTypes} customHelperText={customHelperText} />
    </ActionItemDialog>
);

export default UploadFileDialog;
